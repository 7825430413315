
// sys
export const FileApi = "/api/file/FileApi2"
export const SysEnum_create = "/api/SysEnumController/create"
export const SysEnum_getPage = "/api/SysEnumController/getPage"
export const SysEnumImgType_Delete = "/api/SysEnumController/deleteById"
export const PermissionApi = "/api/user_api/PermissionApi"
// user
export const User_getPage = "/api/UserController/getPage"
export const User_login = "/api/UserController/login"
export const User_logout = "/api/UserController/logout"
export const User_getCurrentUserInfo = "/api/UserController/getCurrentUserInfo"

// img
export const Img_getInfo = "/api/img/getInfo"
export const Img_getPage = "/api/img/getPage"
export const Img_create = "/api/img/create"

export const Img_getImgTree = "/api/img/getImgHome"
export const Img_updateTree = "/api/img/updateTree"
export const Img_updateAllDesign = "/api/img/updateAllDesign"

// Customer
export const Customer_Page = "/api/CustomerController/getPage"
export const Customer_update = "/api/CustomerController/updateById"
