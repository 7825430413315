import Vue from 'vue'
import VueRouter from 'vue-router'
import user from "@/store/user";

// 前端
export const ROOT_URL = '/';
export const admin = '/admin';
export const AdminHome = '/admin/home';
export const AdminImg = '/admin/img';
export const AdminImgType = '/admin/ImgType';
export const adminLogin = '/admin/login';
export const AdminMessageBoard = '/admin/MessageBoard';


export const Home = '/Home';
export const HomeDoor = '/HomeDoor';
export const Info = '/Info';
export const DecorationPlan = '/DecorationPlan';

const NoLoginPaths = [adminLogin,] // 不需要登录即可访问的页面
const LoginPaths = [/^\/(admin)\/.*/i,]

Vue.use(VueRouter)

export const routes = [
    {
        path: ROOT_URL,
        redirect: HomeDoor,
    },
    {
        path: HomeDoor,
        component: () => import('@/views/font/JiangxinHome.vue'), // component: HomeView
        redirect: Home,
        children: [
            {
                path: Home,
                component: () => import('@/views/font/ImgList.vue'),
            },
            {
                path: Info,
                component: () => import('@/views/font/Info.vue'),
            },
            {
                path: DecorationPlan,
                component: () => import('@/views/font/DecorationPlan.vue'),
            },]
    },
    {
        path: admin,
        component: () => import('@/views/admin/JiangxinAdminHome.vue'),
        redirect: AdminImgType,
        children: [
            {
                path: AdminHome,
                component: () => import('@/views/admin/JiangxinAdminHome.vue'),
                children: []
            },
            {
                path: AdminImg,
                component: () => import('@/views/admin/Img/ImgTree.vue'),
            },
            {
                path: AdminImgType,
                component: () => import('@/views/admin/ImgType/ImageTypePage.vue'),
            },
            {
                path: AdminMessageBoard,
                component: () => import('@/views/admin/MessageBoard.vue'),
            },
            // {
            //     path: '/About',
            //     component: About
            // }
            // {
            //     path: '*', // 匹配所有未定义的路径
            //     component: NotFound // 当没有匹配到其他路径时，加载 NotFound 组件
            // }
        ]
    },
    {
        path: adminLogin,
        component: () => import('@/views/Login.vue'),
    },];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach((to, from, next) => {
    if (NoLoginPaths.some(v => v === to.path)) {
        return next();
    }
    let needLogin = LoginPaths.some(v => v.test(to.path)) && !user.state.token;
    if (needLogin) {
        return next({path: adminLogin})   // 用户未登录且尝试访问需要登录的页面  重定向到登录页面
    }
    return next();
});
export default router
