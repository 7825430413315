<template>
  <div id="app" class="app container">
    <router-view/>
  </div>
</template>
<script>
import {HomeDoor} from "@/index";

export default {
  name: "App",
  data() {
    return {
      Home: HomeDoor
    }
  },
  computed: {},
}
</script>
<style lang="less">
.app {
  min-height: 100vh;

  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
</style>
