import Vue from 'vue';
import Vuex from 'vuex';
import {TOKEN} from "@/api/config";
import {getJson} from "@/api/http";
import {User_getCurrentUserInfo} from "@/api/api";

Vue.use(Vuex);
export default {
    namespaced: true,
    state: {
        user: null, // 可以存储用户数据
        token: null, // 内存中,更快, localStorage: 避免刷新后消失, 恢复状态
    },
    mutations: {
        setToken(state, token) {
            if (token == null) {
                state.token = token;
                localStorage.removeItem(TOKEN)
                return
            }
            state.token = token;
            localStorage.setItem(TOKEN, token);
        },
        setUser(state, user) {
            state.user = user;
        },
    },
    actions: {
        async xUserToken({commit}, token) {
            commit('setToken', token);
        },
        async xUser({commit}, user) {
            commit('setUser', user);
        },
    },
    getters: {
        user: state => state.user,
    },
    created() {
        this.state.token = localStorage.getItem(TOKEN);
        getJson(User_getCurrentUserInfo).then((res) => {
            this.state.user = res.data;
        })
    }
};